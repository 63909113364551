import React, { memo, useMemo, useEffect, useState, useCallback } from "react";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import { AdsProviderConstants } from "@/commons/ui/constants/AdsConstants";
import PqConsole from "@/commons/utilities/PqConsole";
import EnvUtility from "@/commons/utilities/EnvUtility";
import OneTrustUtils from "@/commons/tracking/OneTrustUtils";

import AdSense from "./AdSense";
import Criteo from "./Criteo";
import DFP from "./DFP";
import AdvRed from "./AdvRed";
import { useSelector } from "react-redux";

const windowOk = typeof window !== "undefined";

const getADVConfs = (slot) => {
  const { pqConfs } = useSelectorPQGlobalThis();

  let network = pqConfs.ads.networks[slot];
  let originalConfs = pqConfs.ads[network].slots[slot];
  return { originalConfs, network };
};

const checkEnabledADV = (slot, rStateIsBot) => {
  const { pqConfs } = useSelectorPQGlobalThis();
  try {
    const { originalConfs } = getADVConfs(slot);
    return (
      !rStateIsBot &&
      !pqConfs.ads.disabled &&
      originalConfs &&
      (originalConfs.state === undefined || originalConfs.state)
    );
  } catch (error) {
    return false;
  }
};

const checkEnabledADVWithoutBot = (slot) => {
  const { pqConfs } = useSelectorPQGlobalThis();
  try {
    const { originalConfs } = getADVConfs(slot);
    return (
      !pqConfs.ads.disabled &&
      originalConfs &&
      (originalConfs.state === undefined || originalConfs.state)
    );
  } catch (error) {
    return false;
  }
};

const Advertisement = ({ slot, additionalConfs }) => {
  const { ccontext } = useSelector((state) => state);
  const { rStateIsBot } = ccontext;
  const { pqConfs } = useSelectorPQGlobalThis();

  const [renderAdvForOneTrust, setRenderAdvForOneTrust] = useState({
    isUserSetPreferences: OneTrustUtils.isUserSetPreferences,
    isUserActiveAds: OneTrustUtils.getPreferencesUser(OneTrustUtils.KEY.ADS),
    onetrustConsent: pqConfs.onetrust.onetrustConsent,
  });

  const manageUserChooseOneTrustPreferences = (eventOneTrust) => {
    const { detail } = eventOneTrust;
    setRenderAdvForOneTrust({ ...renderAdvForOneTrust, ...detail });
  };

  useEffect(() => {
    if (EnvUtility.isServerSide) return;
    document.body.addEventListener(
      "eventUserChooseOneTrustPreferences",
      manageUserChooseOneTrustPreferences,
    );
    return () => {
      document.body.removeEventListener(
        "eventUserChooseOneTrustPreferences",
        manageUserChooseOneTrustPreferences,
      );
    };
  }, [EnvUtility.isServerSide]);

  const renderAdv = useCallback(() => {
    if (!checkEnabledADV(slot, rStateIsBot)) {
      PqConsole.warning(`Advertisement ${slot}: disabled`);
      return null;
    }
    try {
      const { originalConfs, network } = getADVConfs(slot);

      const confsADV = {
        ...originalConfs,
        ...additionalConfs,
        renderAdvForOneTrust: renderAdvForOneTrust,
      };

      const isUserActiveAds = useMemo(
        () =>
          renderAdvForOneTrust?.isUserSetPreferences &&
          renderAdvForOneTrust?.isUserActiveAds,
        [renderAdvForOneTrust],
      );
      const renderAdv = useMemo(
        () =>
          renderAdvForOneTrust?.onetrustConsent != "TCF1" || isUserActiveAds,
        [renderAdvForOneTrust, isUserActiveAds],
      );
      switch (network) {
        case AdsProviderConstants.ADSENSE:
          return renderAdv && windowOk && <AdSense {...confsADV} />;
        case AdsProviderConstants.CRITEO:
          return renderAdv && <Criteo {...confsADV} />;
        case AdsProviderConstants.DFP:
          return renderAdv && <DFP {...confsADV} />;
        case AdsProviderConstants.ADVRED:
          return renderAdv && <AdvRed {...confsADV} />;
        default:
          PqConsole.warning(
            `Advertisement - Provider: '${network}' not handled`,
          );
          return null;
      }
    } catch (e) {
      PqConsole.error(`Advertisement: ${e.message}`, e);
      return null;
    }
  });

  return renderAdv();
};

Advertisement.defaultProps = {
  slot: null,
  additionalConfs: {},
};

export default memo(Advertisement);
export { getADVConfs, checkEnabledADV, checkEnabledADVWithoutBot };
