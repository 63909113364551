import React, { memo, useRef, useCallback, useEffect, useState, useMemo } from 'react';
import styles from "./AdvRed.module.scss";
import { getCookie } from "cookies-next";

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

const AdvRed = (props) => {
	const {
		method,
		code,
		target,
		dimensions,
		targeting,
		sizeMapping: sizeMappingRules,
		setCollapseEmptyDiv,
		disableInitialLoad,
		renderAdvForOneTrust,
		maxRefreshAttempts,
	} = props;

	const divGptAdMethod = `div-gpt-ad-${method}`;
	const slotPath = `/${code}/${target}`;
	let refreshAttempts = 0;

	const [injectAdsRed, setInjectAdsRed] = useState(false);
	const prevRenderAdvForOneTrust = usePrevious(renderAdvForOneTrust);

	//--- load script DFP / YB ---
	const getStateRenderAdvForOneTrust = useCallback(() => {
		const onetrustConsent = renderAdvForOneTrust.onetrustConsent;
		const newIsUserActiveAds =
			renderAdvForOneTrust &&
			renderAdvForOneTrust.isUserSetPreferences &&
			renderAdvForOneTrust.isUserActiveAds;

		const oldIsUserActiveAds =
			prevRenderAdvForOneTrust &&
			prevRenderAdvForOneTrust.isUserSetPreferences &&
			prevRenderAdvForOneTrust.isUserActiveAds;

		return { newIsUserActiveAds, oldIsUserActiveAds, onetrustConsent };
	}, [prevRenderAdvForOneTrust, renderAdvForOneTrust]);

	useEffect(() => {
		const { newIsUserActiveAds, onetrustConsent } = getStateRenderAdvForOneTrust();

		if (onetrustConsent == 'TCF1' && !newIsUserActiveAds) return;

		injectScriptAdsRed().then((result) => {
			setInjectAdsRed(true);
		});
	}, [renderAdvForOneTrust, prevRenderAdvForOneTrust, getStateRenderAdvForOneTrust]);

	/*
	useEffect(() => {
		if (prevRenderAdvForOneTrust) {
			const { newIsUserActiveAds, oldIsUserActiveAds, onetrustConsent } = getStateRenderAdvForOneTrust();

			if (onetrustConsent == 'TCF1' && !newIsUserActiveAds) return;

			if (oldIsUserActiveAds !== newIsUserActiveAds) {
				if(window && window.__advRed && typeof window.__advRed.adStart !== "undefined") {
					console.log("####### ADVRED", "adStart newIsUserActiveAds");
					window.__advRed.adStart(newIsUserActiveAds);
				}
			}
		}
	}, [renderAdvForOneTrust, prevRenderAdvForOneTrust, getStateRenderAdvForOneTrust]);
	*/

	useEffect(() => {
		if (code == null || target == null || method == null) return;

		//--- confs AdvRed ---
		if (!injectAdsRed) return;
		loadAdsRedConfs();
	}, [injectAdsRed]);

	const loadAdsRedConfs = useCallback(() => {
		let isUserSetPreferences = (renderAdvForOneTrust && renderAdvForOneTrust.isUserSetPreferences) || getCookie("OptanonAlertBoxClosed") !== undefined;
		console.log("@@@ @@@ @@@ AdvRed adStart PageLanding isUserSetPreferences:", isUserSetPreferences);
		
		if(isUserSetPreferences && window.__advRed && typeof window.__advRed.adStart !== "undefined") {
            let AdvRedMarketingConsent = !!renderAdvForOneTrust.isUserActiveAds;
            console.log("@@@ @@@ @@@ AdvRed adStart PageLanding with value:", AdvRedMarketingConsent);
			try {
				window.__advRed.adStart(AdvRedMarketingConsent);
			} catch (error) {
				console.error("Error calling adStart:", error);
			}
        }
	});

	return (
		<div
			data-type={`advred`}
			className={styles.advred}
			key={`ADVRED-${method}`}
			id={divGptAdMethod}></div>
	);
};

const injectScriptAdsRed = () =>
	new Promise((resolve, reject) => {
		//window.googletag = window.googletag || {};
		//window.googletag.cmd = window.googletag.cmd || [];
		//window.googletag.slots = window.googletag.slots || [];

		let script_src = `https://cdn1.adv.red/adv_call_promoqui.js`;

		// uncomment only for Staging
		// script_src = `https://cdn1.adv.red/stg/adv_call_promoqui.js`;

		const refScript = document.querySelector("script[data-type='AdvRed']");
		if (!refScript) {
			const script = document.createElement('script');
			script.dataset.type = 'AdvRed';
			script.type = 'text/javascript';
			script.async = true;
			script.onload = () => resolve(true);
			script.src = script_src;
			document.getElementsByTagName('head')[0].appendChild(script);
		} else {
			resolve(true);
		}
	});

AdvRed.defaultProps = {
	code: null,
	target: null,
	method: null,
	dimensions: [],
	targeting: [],
	sizeMapping: [],
	setCollapseEmptyDiv: true,
	disableInitialLoad: false,
	renderAdvForOneTrust: {},
};

export default memo(AdvRed, (prev, next) => prev.method == next.method);
export { injectScriptAdsRed };
